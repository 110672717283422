import * as React from 'react';
import { ReactElement } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

// DESIGN SYSTEM
import { Col, Row } from '@utmb/design-system/box/Box';
import { Font } from '@utmb/design-system/text/font';
import { Icon } from '@utmb/design-system/icons/Icon';
import { Image } from '@utmb/design-system/image/Image';
import { Separator } from 'src/design-system/separator/Separator';
import { Slides, SlidesItem } from '@utmb/design-system/slides/Slides';
import { Spacer } from '@utmb/design-system/spacer/Spacer';
// INTERFACES
import { IconsEnum } from '@utmb/types/icons';
import { PromotionView, SlicePromotionSliderView } from 'src/types';
// STYLES
import styles from './slider.module.scss';
// import { useRouter } from 'next/router';

interface PromoSliderProps extends SlicePromotionSliderView {
    isDark?: boolean;
}

const { FuturaHeavy, FuturaBook } = Font;

export const PromoSlider = (props: PromoSliderProps): ReactElement => {
    const { promotions, isDark } = props;

    const isInternalLink = (link: string) => {
        const utmbWorldRegExp = new RegExp('/utmb.world/g');
        return link.match(utmbWorldRegExp);
    };

    // const router = useRouter();

    return (
        <Slides
            className={styles.ps_slide_container}
            pagination={true}
            isDarkPagination={isDark}
            carouselOptions={{
                containScroll: 'trimSnaps',
                align: 'center',
                draggable: true,
            }}
        >
            {() => (
                <>
                    {promotions.map((slide: PromotionView, index: number) => (
                        <SlidesItem key={index} className={styles.ps_slide_item}>
                            {slide.link ? (
                                isInternalLink(slide.link) ? (
                                    <Link href={slide.link}>
                                        <a target="_self">
                                            <PromoSlide {...slide} />
                                        </a>
                                    </Link>
                                ) : (
                                    <a href={slide.link} target="_blank" rel="noreferrer">
                                        <PromoSlide {...slide} />
                                    </a>
                                )
                            ) : (
                                <PromoSlide {...slide} />
                            )}
                        </SlidesItem>
                    ))}
                </>
            )}
        </Slides>
    );
};

export const PromoSlide = (props: PromotionView): ReactElement => {
    const { t } = useTranslation();
    const { picture, title, content, link } = props;
    return (
        <Row className={styles.ps_slide_content}>
            <div className={styles.image_container}>
                <Image objectFit="cover" layout="fill" image={picture} objectPosition="bottom" />
            </div>
            <Col className={styles.ps_card}>
                {title && (
                    <FuturaHeavy mobile="16" className="font-uppercase">
                        {title}
                    </FuturaHeavy>
                )}
                <Spacer />
                <FuturaBook mobile="13" desktop="14" className={styles.ps_content}>
                    {content && content}
                </FuturaBook>
                {link ? (
                    <Row className={`cross-center ${styles.moreinfo}`}>
                        <Font.OxaniumRegular mobile="14" className="font-uppercase">
                            {t('promotionSlider.seeMore')}
                        </Font.OxaniumRegular>
                        <Separator.Vertical weight="5px"></Separator.Vertical>
                        <Icon variant={IconsEnum.RIGHT} />
                    </Row>
                ) : (
                    <></>
                )}
            </Col>
        </Row>
    );
};
