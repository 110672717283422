import { ReactElement } from 'react';
import { PromoSlider } from './slider';

// DESIGN SYSTEM
import { Row } from '../../design-system/box/Box';

// STYLES
import styles from './promotions-slider.module.scss';

// INTERFACES
import { Slice } from '@utmb/types/slice-handler';
import { SlicePromotionSliderView } from 'src/types';

export const PromotionSlider = (props: Slice<SlicePromotionSliderView>): ReactElement | null => {
    const { id, promotions, isDark } = props;
    const sliderClasses = ['utmb_container', 'row-wrap', styles.ps_container].filter(Boolean).join(' ');

    if (!promotions || !promotions.length) {
        return null;
    }

    return (
        <Row id={id} className={sliderClasses}>
            <PromoSlider isDark={isDark} promotions={promotions} />
        </Row>
    );
};
